<template>
    <v-card class="pa-0 ma-0 elevation-0 my-border ">
      <div class="pa-3 white" style="min-height:calc(100vh - 130px)">
        <v-form v-on:submit.prevent ref="form" v-model="valid" lazy-validation>
          <v-row class="my-0 px-2">
              <v-col cols="12" class="pa-0 ma-0">
                <div style="font-size:1.3em" class="pa-1 float-left font-weight-medium text-left text--secondary">{{$t('newtask')}}</div>
                <v-btn class="custom-color-accent pa-0 mt-0 ma-1 float-right" depressed 
                  :loading="loading4"
                  :disabled="loading4 || SaveBtn" 
                  @click="save">{{$t("save")}}</v-btn>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-0 mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                      <span class="d-flex text--disabled text-capitalize">{{ $t('Activity') }}: </span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0 text-center">
                      <automate-activity
                        class="ma-0 pa-0 justiy-center"
                        ref="autoactivity"
                        @setTitle="setTitle"
                        @automateTask="setAutomate"
                        @disableSaveBtn="disableSaveBtn"
                        ></automate-activity>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-auto mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                      <span class="d-flex text--disabled text-capitalize">{{ $t('title') }}: </span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0 text-center">
                          <v-text-field
                            v-model="editedTask.title"
                            class="ma-0 pa-0 justiy-center white"
                            dense
                            :label="$t('title')"
                            :placeholder="$t('title')"
                            hide-details 
                            :rules="[rules.required,rules.min,rules.max]"
                            outlined
                          ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 py-1 h-100 ma-0">
                  <v-row class="pa-auto mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                      <span class="d-flex my-auto text--disabled text-capitalize">{{ $t('description') }}: </span>
                    </v-col>
                    <v-col cols="12" sm="9" md="10" class="pa-1 ma-0">
                        <v-textarea v-if="$checkPermission('Tasks.Create')" clearable outlined v-model="editedTask.description" :label="$t('description')" :placeholder="$t('description')" rows="4" dense auto-grow hide-details class="ma-0 pa-0 justiy-center white"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-auto mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                      <span class="d-flex text--disabled text-capitalize">{{ $t('datecreated') }}: </span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0 text-center">
                          <v-text-field
                            v-model="editedTask.date_created"
                            class="ma-0 pa-0 justiy-center white"
                            :label="$t('datecreated')"
                            :placeholder="$t('datecreated')"
                            dense
                            hide-details 
                            :rules="[rules.required]"
                            outlined
                            readonly
                          ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-auto mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                      <span class="d-flex text--disabled text-capitalize">{{ $t('datedue') }}: </span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0 text-center">
                      <v-menu
                        v-model="due"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        class="ma-0 pa-0 justiy-center"
                        hide-details
                        dense
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedTask.due_date"
                            class="ma-0 pa-0 justiy-center white"
                            v-on="on"
                            :label="$t('datedue')"
                            :placeholder="$t('datedue')"
                            dense
                            hide-details 
                            :rules="[rules.required]"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker dark :min="nowDate" v-model="editedTask.due_date" @input="due = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                    <v-row class="pa-0 mx-3 my-auto">
                      <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                        <span class="d-flex text--disabled text-capitalize">{{ $t('assignee') }}: </span>
                      </v-col>
                      <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0">
                          <v-autocomplete clearable v-model="editedTask.assigned_to" item-value="id" item-text="full_name" :label="$t('assignee')" :placeholder="$t('assignee')"
                          :items="users" outlined class="ma-0 pa-0 white" dense hide-details></v-autocomplete>
                      </v-col>  
                    </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-0 mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0" >
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('request') }}:</span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0">
                      <v-autocomplete clearable v-model="editedTask.request_id" item-value="id" item-text="reqCode"  :rules="[rules.required]" :label="$t('requestid')" :placeholder="$t('requestid')"
                            :items="requests" outlined class="ma-0 pa-0 white" dense hide-details></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                  <v-row class="pa-0 mx-3 my-auto">
                    <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0" >
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('type') }}:</span>
                    </v-col>
                    <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0">
                      <v-autocomplete 
                          :items="getLookup('TaskType').slice(0,3)" :label="$t('type')" :placeholder="$t('type')"
                          outlined
                          class="ma-0 pa-0 white" dense hide-details
                          :rules="[rules.required]"
                          v-model="editedTask.type">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" class="ma-0 pa-1 task-column">
                <div class="d-flex text-left customlightgray my-border pa-0 h-100 ma-0">
                    <v-row class="pa-0 mx-3 my-auto">
                      <v-col cols="12" sm="3" md="2" class="pa-0 pa-sm-auto ma-0">
                        <span class="d-flex text--disabled text-capitalize">{{ $t('labels') }}: </span>
                      </v-col>
                      <v-col v-if="$checkPermission('Tasks.Create')" cols="12" sm="9" md="10" class="pa-1 ma-0">
                          <v-autocomplete
                            small-chips
                            multiple
                            class="ma-0 pa-0 white" dense hide-details
                            :items="getLookup('TaskLabel')"
                            :label="$t('labels')"
                            :placeholder="$t('labels')"
                            item-text="text"
                            outlined
                            item-value="value"
                            v-model="editedTask.label"
                          > 
                            <template v-slot:selection="data">
                              <v-chip :input-value="data.selected" small :color="setcolor(data.item.value)">
                                  <strong>{{ getStatusLookup('TaskLabel', data.item.value)[0] }}</strong>
                              </v-chip>
                            </template>
                          </v-autocomplete>
                      </v-col>  
                    </v-row>
                </div>
              </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
</template>
<script>
import {mapActions, mapState, mapGetters} from "vuex";
import moment from "moment";
import AutomateActivity from "@/components/task/AutomateActivity";
export default {
    components: {
      'automate-activity': AutomateActivity
    },
  data() {
    return {
      due:false,
      editedTask:{title:'',date_created:new moment().format("YYYY-MM-DD"),status:1},
      nowDate: new moment().format("YYYY-MM-DD"),
      isopen:true,
      valid: false,
      due_date:null,
      loader: null,
      id: null,
      SaveBtn: true,
      loading4: false,
      rules: {
        required: value => !!value || this.$t('required'),
        min: value => (value && value.length >= 3) || this.$t('mincharacters3'),
        max: value => (value && value.length <= 200) || this.$t('maxcharacters200'),
      },
      autoTask:{},
    }
  },
  computed:{
    ...mapState({
      users: "users"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
    ...mapGetters('template', {
      templates: 'getTemplatebyLanguage'
    }),
     ...mapState(['user']),
     ...mapState('request',{
      'requests':'requests'
    }),
  },
  async created() {
    this.editedTask.request_id=this.$route.params.id;
    await this.retriveRequests();
    await this.retriveUsers();
    await this.retriveConfigurations();
    await this.retriveTemplate();
    if(this.$refs.form.validate())
        this.disableSaveBtn(false);
    else
        this.disableSaveBtn(true);
  },
  watch:{
    loader() {
    const l = this.loader;
    this[l] = !this[l];
    this.loader = null;
    },
    editedTask:{ handler(val){
      if(this.$refs.form.validate())
          this.disableSaveBtn(false);
      else
          this.disableSaveBtn(true);
      if(val.label&&!Array.isArray(val.label))
      this.localtask.label = JSON.parse( val.label )
      }, deep: true
    },
  },
  methods:{
    ...mapActions('request', ['retriveRequests']),
    ...mapActions([ "retriveUsers"]),
    ...mapActions('configuration',['retriveConfigurations']),
    ...mapActions("request", ["updateRequest"]),
    ...mapActions("documents",['updateDocument']),
    ...mapActions("email", ["sendEmail"]),
    ...mapActions('template',['retriveTemplate']),
    ...mapActions('task',['postTask',"postTaskLog"]),

    disableSaveBtn(val){
      this.SaveBtn = val;
    },
    setAutomate(automate){
      this.autoTask = automate;
    },
    save(){
      if(this.$refs.form.validate()){
        this.loader = 'loading4';
        this.postTask(this.editedTask).then(async response => {
          this.id = response.data.id;
          let requestField = this.configurations
              .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'R')
              .map(item =>item.LookUpOther );
          let documentField = this.configurations
              .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'D')
              .map(item =>item.LookUpOther );
          let emailField = this.configurations
            .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'E')
            .map(item =>item.LookUpOther );

          let allKey = Object.keys(this.autoTask);
          if( allKey.some((i)=>requestField.includes(i))){
            let req={}
            req.id = this.editedTask.request_id;
            let og_req=this.requests.filter(item=>item.id==req.id)[0];
            req.RequestStatus = this.autoTask.requestStatus?this.autoTask.requestStatus:og_req.RequestStatus;
            req.SecurityClass = this.autoTask.requestSecurityClass?this.autoTask.requestSecurityClass:og_req.SecurityClass;
            req.DateDue = this.autoTask.requestDateDue?this.autoTask.requestDateDue:og_req.DateDue;
            req.RequestText = this.autoTask.requestText?this.autoTask.requestText:og_req.RequestText;
            req.ATIPOfficerId = this.autoTask.requestATIPOfficerId?this.autoTask.requestATIPOfficerId:og_req.ATIPOfficerId;
            if(this.autoTask.requestATIPOfficerId=='notassigned')
              req.ATIPOfficerId=null;
            req.RequestDisposition = this.autoTask.requestDisposition?this.autoTask.requestDisposition:og_req.RequestDisposition;
            req.DateOfClosure=this.autoTask.requestDateOfClosure?this.autoTask.requestDateOfClosure:og_req.DateOfClosure;
            if(this.autoTask.requestStatus && this.autoTask.requestStatus!='Complete')
              req.DateOfClosure=null;

            this.updateRequest(req);
            }
          if( allKey.some((i)=>documentField.includes(i))){
            let document={}
            document.id = this.autoTask.docId;
            if(this.autoTask.documentStatus)
              document.status = this.autoTask.documentStatus;
            this.updateDocument(document);
          }
          if( allKey.some((i)=>emailField.includes(i))){
            let template = await this.templates.filter( item => item.id === this.autoTask.emailTemplate )[0];
            let newemail={
              requestId:null,
              userId:JSON.parse(localStorage.getItem('user')).id,
              date_sent:moment().format("YYYY-MM-DD"),
              data:{
                sendTo:[],
                subject:"",
                body:"",
                replyTo:JSON.parse(localStorage.getItem('user')).email,
                cc:[],
                bcc:[],
                attachments:[]
              },
              user:JSON.parse(localStorage.getItem('user'))
            };
            newemail.data.sendTo = this.autoTask.sendTo;
            newemail.data.cc = this.autoTask.cc?this.autoTask.cc:[];
            newemail.data.bcc = this.autoTask.bcc?this.autoTask.bcc:[];
            newemail.data.body = this.filterContent(template.content);
            newemail.data.subject = this.filterContent(template.name);
            const formData = new FormData();
            formData.append('requestId', this.editedTask.request_id);
            formData.append('userId', JSON.parse(localStorage.getItem('user')).id);
            formData.append('date_sent', moment().format("YYYY-MM-DD"));
            formData.append( 'sendTo', newemail.data.sendTo );
            formData.append( 'attachment', newemail.data.attachments );
            formData.append( 'subject', newemail.data.subject );
            formData.append( 'replyTo', JSON.parse(localStorage.getItem('user')).email );
            formData.append( 'cc', newemail.data.cc );
            formData.append( 'bcc', newemail.data.bcc );
            formData.append( 'user', JSON.parse(localStorage.getItem('user')) );
            formData.append( 'body', newemail.data.body );
            this.sendEmail(formData);
          }
          this.loading4 = false; 
          this.loader=false;
          let item = {text:this.$t("taskAddAlert"), type:"success", alert:true};
          this.$root.$emit("callAlert", item);
          this.openInSame({name:'tasks',params:{id:this.id}});
        })
      }
      else{
        this.disableSaveBtn(true);
        this.loading4=false;
        this.loader=false;
      }
    },
    filterContent(content){
        if(content){
          return content.replace(/\$(\w+.\w+)/g, (_, v) => {
            let string = v.split('.');
            let currentRequest = this.requests.filter(item=>item.id==this.editedTask.request_id)[0];
            if( string.length >= 1 ){
              if(string[0] === 'address'){
                return currentRequest[string[0]][string[1]];
              }
              else if(string[0] == 'date'){
                return moment().format("YYYY-MM-DD");
              }
              else if(string[0] == 'user'){
                return this.user[string[1]];
              }else{
                return currentRequest[string[0]];
              }
            }
            else{
              return currentRequest[string[0]];
            }
          });
        }
      },
    openInSame(r){
      this.$router.push(r);
    },
    getStatusLookup(column = 'TaskLabel', label){
      return this.configurations
        .filter(filter => filter.LookUpName === column && filter.LookUpKey == label)
        .map(item => {
          
            return localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          
        });
    },
    getLookup(column) {
        return this.configurations
            .filter(filter => filter.LookUpName === column)
            .map(item => {
            let arr = [];
            arr["text"] =
                localStorage.getItem("language") === "en"
                ? item.LookUpStringE
                : item.LookUpStringF;
            arr["value"] = item.LookUpKey;
            return arr;
            });
    },
    setcolor(status) {
        if (status === "done") {
          return "success";
        } else if (status === "onHold") {
              return "warning";
        } else if (status === "ongoing") {
          return "primary";
        } else if (status === "todo") {
          return "gray";
        } else if (status === "high") {
          return "error";
        } else if (status === "medium") {
          return "warning";
        } else if (status === "low") {
          return "gray";
        } else{
          return "gray"
        }
    },
    setTitle(item){
      if(item)
        this.editedTask.title=item;
      else
        this.editedTask.title='';
    },
  }
}
</script>
<style lang="scss" scoped>
.task-column{
  position: relative;
  min-height: 75px;
}
.h-100{
    height: 100%;
}
</style>